
$color-active-tab: #fff;
$color-background: rgb(240, 240, 240);
// old inactive tab background was rgb(240, 240, 240)

.form-tab {
    margin-bottom: -1px;
    color: black;
    background-color: $color-background;
    .active {
        background-color: $color-active-tab;
    }
}

.header-row {
    align-items: flex-end;
    justify-content: flex-end;
}

.tab-content-container {
    display: flex;
    flex: 1;
    justify-content: center;
    height: 100%;
    border: 1px solid $color-background;
    background: $color-active-tab;
    border-top-left-radius: 1rem;
    width: 100%;
}

.tab-pane {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 1rem;
}

