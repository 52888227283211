.container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.info-card {
    display: flex;
    flex-direction: column;
    flex: 0;
    align-items: center;
    margin: 0 0.5rem;
    line-height: 1rem;
    @media screen and (max-width: 991px) {
        margin: 0;
    }
}

.info-card-body {
    width: 100%;
}

.case-management-summary-table {
    width: 100%;
    td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    tr:nth-child(even) {
        background-color: #dddddd;
    }
}
