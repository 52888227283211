.lists-container {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.list {
    flex-direction: row;
    min-width: 20rem;
    flex: 1;

    video {
        min-width: 10rem;
        position: relative;
        width: 100%;
    }
}

.list-item {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: row;
}
