.links-profile-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    flex: 1;
    justify-content: flex-end;
    margin: 15px 1rem;
    text-align: end;
}

.profile-logout {
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
}
