.dashboard-card {
    border-radius: 5px;
}

.dashboard-card-title {
    display: flex;
    margin: 10px 10px 0 10px;
    justify-content: center;
    text-align: center;
}

.dashboard-card-body {
    display: flex;
    flex-direction: column;
    padding-top: 0;
}
